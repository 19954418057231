import { template as template_b738ec61887f4d7f85dce33daa5d3d91 } from "@ember/template-compiler";
const FKLabel = template_b738ec61887f4d7f85dce33daa5d3d91(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
