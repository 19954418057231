import { template as template_b0874b581c9742f795c504e4d86aa367 } from "@ember/template-compiler";
const WelcomeHeader = template_b0874b581c9742f795c504e4d86aa367(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
