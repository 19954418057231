import { template as template_153ce95f2f614d368d713536b5c04526 } from "@ember/template-compiler";
const SidebarSectionMessage = template_153ce95f2f614d368d713536b5c04526(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
