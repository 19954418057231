import { template as template_17af13372ab1455e96e14af0c63e6cfa } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_17af13372ab1455e96e14af0c63e6cfa(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
