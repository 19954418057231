import { template as template_247466011d0e40689b021b168fd31f64 } from "@ember/template-compiler";
const FKText = template_247466011d0e40689b021b168fd31f64(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
